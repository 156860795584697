<template>
    <div class="com-vr-panorame">
        <muz-threes :three-config="threeConfig"></muz-threes>
    </div>
</template>

<script>
import MuzThrees from 'muz-threes';
export default {
    components: {
        MuzThrees,
    },
    data: () => ({
        threeConfig: {
            /** 基础配置（单位：米） */
            config: {
                /** @brief 相机位置 */
                position: {x: -20, y: 10, z: -20},
                /** @brief 相机朝向 */
                lookAt: {x: 0, y: 0, z: 0},
                
                grid: {},
                axes: {},
                
                /** @brief 视轨控制器 */
                orbit: {
                    mapControl: false, //是否启用为地图控制模式
                    /* 以下原生属性 */
                    target: {x: 0, y: 0, z: 0},
                    autoRotate: true, //自动旋转
                },
            },
            /** 各种物体 */
            object3Ds: [
                // {
                //     url: './models/tree/tree.gltf',
                //     type: 'gltf',
                //     scale: {x: 1, y: 1, z: 1},
                //     position: {x: 0, y: 0, z: 0},
                // },
                // {
                //     url: './models/mttu/MTTU-125CF-coldface.stl',
                //     type: 'stl',
                //     scale: {x: 100, y: 100, z: 100},
                //     position: {x: 0, y: 0, z: 0},
                // },
            ],
            panoramas: [
                {
                    map: './models/earth/earth_8k.jpg',
                    radius: 50,
                }
            ]
        }
    }),
}
</script>

<style>
    .com-vr-panorame{top: 20vh; right: 0; width: 33%; height: 20vh; position: absolute; z-index: 1;}
</style>
